<!--
	This is the voting page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
	It uses the CardVotingTable component in:
	"./components/Cards/CardVotingTable.vue" .
	It gets the data from the store in:
	"./store/actions.js" .
 -->

<template>
	<div>
		<a-row  :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<CardVotingActions
					:data="table1Data"
					:columns="table1Columns">
				</CardVotingActions>

			</a-col>

		</a-row>
		<!-- Voting Table -->
		<a-row v-if="this.building_id=='demo'" :gutter="24" type="flex">

			<!-- Voting Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Voting Table Card -->
				<CardVotingTable
					:v-if="actionTableVisible"
					:data="table1SampleData"
					:columns="table1Columns"
				></CardVotingTable>
				<!-- / Voting Table Card -->

			</a-col>
			<!-- / Voting Table Column -->

		</a-row>
		<!-- / Voting Table -->

	</div>
</template>

<script>

	// "Voting" table component.
	import CardVotingTable from '../components/Cards/CardVotingTable.vue';
	import CardVotingActions from '../components/Cards/CardVotingActions.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'

	// "Voting" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Topic',
			dataIndex: 'item_w_key',
			scopedSlots: { customRender: 'item_w_key' },
			sorter: (a, b) => a.item_w_key.item.localeCompare(b.item_w_key.item), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Type',
			dataIndex: 'type',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Resolution',
			dataIndex: 'details',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},

		{
			title: 'Status',
			scopedSlots: { customRender: 'status' },
			sorter: (a, b) => a.status.localeCompare(b.status), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Votes',
			dataIndex: 'votes',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Start Date',
			dataIndex: 'start_date',
			class: 'font-semibold text-muted text-sm',
			width: 120,
			sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'End Date',
			dataIndex: 'end_date',
			class: 'font-semibold text-muted text-sm',
			width: 120,
			sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},


	];

	// "Voting" table sample data.
	const table1SampleData = [  
			{
				"item_w_key": {
					"item": "Replace insurance provider",
					"key": "1"
				},
				"type": "Board",
				"details": "Bid out co-op insurance policy",
				"status": "Open",
				"votes": "6/8",
				"start_date": "07/31/2023",
				"end_date": "12/15/2023"
			},
			{
				"item_w_key": {
					"item": "Reduce common charges",
					"key": "1"
				},
				"type": "Board",
				"details": "Common charges change from $1,000 to $800 per month",
				"status": "Scheduled",
				"votes": "0/8",
				"start_date": "09/30/2023",
				"end_date": "10/15/2023"
			},
			{
				"item_w_key": {
					"item": "Integrate Domos Platform",
					"key": "1"
				},
				"type": "Board",
				"details": "Integrate Domos platform for building operations",
				"status": "Closed",
				"votes": "8/8",
				"start_date": "07/31/2023",
				"end_date": "07/31/2023"
			},
			{
				"item_w_key": {
					"item": "Renovate Elevator",
					"key": "1"
				},
				"type": "Shareholders",
				"details": "Elevator renovation budget of $100,000",
				"status": "Closed",
				"votes": "67/70 (98%)",
				"start_date": "10/30/2023",
				"end_date": "11/05/2023"
			},

	]






	export default ({
		components: {
		    CardVotingTable,
			  CardVotingActions,
			  
		},
		data() {
			return {
				actionTableVisible: false,
				// Associating "Voting" table columns with its corresponding property.
				table1Columns: table1Columns,
				table1SampleData: table1SampleData,
				building_id: '',

			}
		},
		computed: {
			...mapState({
				table1Data: state => state.actions.actions,
			})
		},
		methods: {
			...mapActions({
				getActions: 'actions/getActions',
			}),
		},
		async mounted() {
			await this.getActions();
			//get building id from root, auth, user, building_id
			this.building_id = this.$store.state.auth.user.building_id
			this.actionTableVisible = true
			window.analytics.page('Voting')
		},
	})

</script>

<style lang="scss">

.details {
    white-space: pre-wrap;
}
</style>
